import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CurrencyPipe } from '@angular/common';
import { AlertController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LoadingController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-detalles-cuenta',
  templateUrl: './detalles-cuenta.component.html',
  styleUrls: ['./detalles-cuenta.component.scss'],
})
export class DetallesCuentaComponent implements OnInit {
  private modal: HTMLIonModalElement | null = null; // Añade esta línea
  @Input() catalogos: any;
  @Input() cuenta: any;
  @Input() pagos: any;
  @Input() cliente: any;
  porCobrar: any;
  abono: any;
  totalAbono: Number;
  user: any;
  venta_detalle: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef, 
    private currencyPipe: CurrencyPipe,
    private alertController: AlertController,
    private router: Router,
    private http: HttpClient,
    private modalController: ModalController, 
    private loadingController: LoadingController) { }

  ngOnInit() {

    ////console.log('cliente', this.cliente);
    this.user = JSON.parse(localStorage.getItem('user'));
    this.porCobrar = 0;
    this.abono = {}
    this.abono.efectivo = 0;
    this.abono.banco = 0;
    this.abono.electronico = 0;
    console.log('id_venta', this.cuenta.id_venta);
    this.renderDetalles();

    this.pagos.forEach(item => {
      this.porCobrar += Number(item.efectivo);
      this.porCobrar += Number(item.banco);
      this.porCobrar += Number(item.electronico);
    });

  }

  async mostrarAlerta(titulo, mensaje) {
    const alert = await this.alertController.create({
      header: titulo,
      message: mensaje,
      buttons: ['OK']
    });

    await alert.present();
  }

  cerrarModal() {
    if (this.modal) {
      this.modal.dismiss();
      this.modal = null;
    }
  }

  async enviarAbono() {
    this.totalAbono = 0;
    if (!this.abono.id){
      this.abono.id = '';
      this.abono.id_empresa = this.user.id_empresa;
      this.abono.id_sucursal = this.user.id_sucursal;
      this.abono.id_usuario = this.user.id_usuario;
      this.abono.id_venta = this.cuenta.id_venta;
      this.abono.num_corte = 1;
      // Obtener la fecha y hora actuales
      const ahora = new Date();
      // Formatear la fecha y hora en el formato aaaa-mm-dd hh:mm
      const año = ahora.getFullYear();
      const mes = (ahora.getMonth() + 1).toString().padStart(2, '0'); // +1 porque getMonth() devuelve un índice basado en 0
      const día = ahora.getDate().toString().padStart(2, '0');
      const hora = ahora.getHours().toString().padStart(2, '0');
      const minutos = ahora.getMinutes().toString().padStart(2, '0');

      this.abono.fecha_ingreso = `${año}-${mes}-${día} ${hora}:${minutos}`;
    }

    this.totalAbono = Number(this.abono.efectivo) + Number(this.abono.banco) + Number(this.abono.electronico);
    ////console.log(this.totalAbono);

    if  ((this.cuenta.total - this.porCobrar) < this.totalAbono ) {

      this.mostrarAlerta('Atención!', 'No se puede registrar que el pago sea mayor a total por cobrar.');
      return;

    }

    await this.presentLoading('Cargando...');
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = new HttpHeaders({
      "X-API-KEY": 'RpoXa1m5s59vDMOIDmI5uJCdWP2pgnaAuuyTREv'
    });

    this.http.post<any>('https://api.ordex.com.mx/finanzas/crud_pago_ordex', this.abono, { headers: headers })
      .subscribe(data => {

          //console.log('data', data);
          this.dismissLoading();
          //console.log(this.abono);
          this.mostrarAlerta('Bien!', 'Se ha registrado un pago correctamente.');
          this.abono = {}
          this.abono.efectivo = 0;
          this.abono.banco = 0;
          this.abono.electronico = 0;

          if ((Number(this.porCobrar) + Number(this.totalAbono)) == this.cuenta.total) {
            this.cuenta.id_status = 1;
            this.editarVenta();
          }

          ////console.log('cliente', this.cliente);
          // cerrar todos los modales abiertos y redireccionar a ./detalles-cliente/1662208005
          setTimeout(() => {
            this.modalController.dismiss().then(() => {
              this.cuenta.id_status = 1;
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/detalles-cliente', this.cliente.id_cliente]);  
              });
            });
          }, 1500);

      }, error => {
        console.error('Error en la solicitud', error);
        this.dismissLoading();
      });


  }

  async renderDetalles() {

    await this.presentLoading('Cargando...');
    const headers = new HttpHeaders({
      "X-API-KEY": 'RpoXa1m5s59vDMOIDmI5uJCdWP2pgnaAuuyTREv'
    });

    this.http.post<any>('https://api.ordex.com.mx/ventas/venta_detalles2_ordex', this.cuenta, { headers: headers })
      .subscribe(data => {

        this.dismissLoading();
        this.venta_detalle = data.venta_detalle;

      }, error => {
        console.error('Error en la solicitud', error);
        this.dismissLoading();
      });


  }

  async presentLoading(message: string) {
    const loading = await this.loadingController.create({
      message: message,
    });
    await loading.present();
  }

  async dismissLoading() {
    await this.loadingController.dismiss();
  }

  async editarVenta() {
    
    const venta = {
      id_empresa:this.cuenta.id_empresa,
      id_sucursal:this.cuenta.id_sucursal,
      id_usuario:this.cuenta.id_usuario,
      id_venta:this.cuenta.id_venta,
      id_status:this.cuenta.id_status,
    }

    const headers = new HttpHeaders({
      "X-API-KEY": 'RpoXa1m5s59vDMOIDmI5uJCdWP2pgnaAuuyTREv'
    });

    this.http.post<any>('https://api.ordex.com.mx/ventas/crud_venta_ordex', venta, { headers: headers })
      .subscribe(data => {

          //console.log('venta actualizada');

      }, error => {

        console.error('Error en la solicitud', error);
        this.dismissLoading();

      });


  }



}
