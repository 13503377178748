import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-nuevo-cliente',
  templateUrl: './nuevo-cliente.component.html',
  styleUrls: ['./nuevo-cliente.component.scss'],
})
export class NuevoClienteComponent implements OnInit {

  constructor(
    private alertController: AlertController,
    private router: Router,
    private http: HttpClient,
    private modalController: ModalController, 
    private loadingController: LoadingController) { }
  

  private modal: HTMLIonModalElement | null = null; // Añade esta línea
  cliente: any;

  ngOnInit() {

    const user = JSON.parse(localStorage.getItem('user'));
    if (!this.cliente.id) {

      this.cliente.id = '';
      this.cliente.id_empresa = user.id_empresa;
      this.cliente.id_sucursal = user.id_sucursal;
      this.cliente.id_usuario = user.id_usuario;

    }
  }

  async crudCliente() {

    if (this.cliente.nom_cliente == '') {
      return;
    }

    await this.presentLoading('Cargando...');

    const headers = new HttpHeaders({
      "X-API-KEY": 'RpoXa1m5s59vDMOIDmI5uJCdWP2pgnaAuuyTREv'
    });

    this.http.post<any>('https://api.ordex.com.mx/clientes/crud_clientes_ordex', this.cliente, { headers: headers })
      .subscribe(data => {
          
          //console.log(this.catalogos);
          this.dismissLoading();
          this.mostrarAlerta('Bien!', 'Cliente Agregado con exito');
          this.modalController.dismiss().then(() => {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/detalles-cliente', data.cliente.id_cliente]);  
            });
          });


      }, error => {
        console.error('Error en la solicitud', error);
        this.dismissLoading();
      });

  }

  async presentLoading(message: string) {
    const loading = await this.loadingController.create({
      message: message,
    });
    await loading.present();
  }

  async dismissLoading() {
    await this.loadingController.dismiss();
  }


  async mostrarAlerta(titulo, mensaje) {
    const alert = await this.alertController.create({
      header: titulo,
      message: mensaje,
      buttons: ['OK']
    });

    await alert.present();
  }
  
  cerrarModal() {
    if (this.modal) {
      this.modal.dismiss();
      this.modal = null;
    }
  }



}
