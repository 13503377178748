import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DetallesCuentaComponent } from '../detalles-cuenta/detalles-cuenta.component';

@Component({
  selector: 'app-modales',
  templateUrl: './modales.component.html',
  styleUrls: ['./modales.component.scss'],
})
export class ModalesComponent implements OnInit {
  @Input() ventas: any;
  @Input() pagos: any;
  @Input() cliente: any;
  ventasFiltradas: any;
  modal: any;
  catalogos: any;
  pagosFiltrados: any;
  cuenta: any;

  constructor(private modalController: ModalController) { }

  ngOnInit() {

    //console.log('cliente', this.cliente);
    this.ventasFiltradas = this.ventas.filter(venta => venta.id_status == 2);

    //console.log(this.ventasFiltradas);

  }

  async presentModaleDetCuenta(datos) {

    this.modal.dismiss();

    //console.log('pagos', this.pagos);
    this.cuenta = datos;
    this.pagosFiltrados = this.pagos.filter(item =>
      item.id_venta == this.cuenta.id_venta);

    this.modal = await this.modalController.create({
      component: DetallesCuentaComponent,
      componentProps: { catalogos: this.catalogos, cuenta: this.cuenta, cliente: this.cliente, pagos: this.pagosFiltrados },
    });

    await this.modal.present();

    this.modal = null;

  }

  
  cerrarModal() {
    if (this.modal) {
      this.modal.dismiss();
      this.modal = null;
    }
  }


}
