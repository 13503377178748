import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CurrencyPipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-nueva-cuenta',
  templateUrl: './nueva-cuenta.component.html',
  styleUrls: ['./nueva-cuenta.component.scss'],
})
export class NuevaCuentaComponent implements OnInit {
  private modal: HTMLIonModalElement | null = null; // Añade esta línea
  @Input() catalogos: any;
  @Input() cuenta: any;
  @Input() cliente: any;
  catalogos2: any;
  dataConcepto: any;
  venta_detalle: any;
  fechaRegistro: string;
  fechaVencimiento: string;
  cargoTotal: number;
  cargoTotalFormateado: string;
  today: string;
  user: any;
  unidadMedidaOptions: any;
  convenios: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef, 
    private currencyPipe: CurrencyPipe,
    private modalController: ModalController, 
    private router: Router,
    private http: HttpClient,
    private loadingController: LoadingController,
    private alertController: AlertController) {
    this.today = new Date().toISOString().split('T')[0];
  }

   async mostrarAlerta(titulo, mensaje) {
    const alert = await this.alertController.create({
      header: titulo,
      message: mensaje,
      buttons: ['OK']
    });

    await alert.present();
  }

  ngOnInit() {

    this.unidadMedidaOptions = ['Kilogramos', 'Gramos', 'Toneladas', 'Libras', 'Cajas', 'carton', 'paquetes'];
    this.user = JSON.parse(localStorage.getItem('user'));
    this.venta_detalle = [];
    this.dataConcepto = {}
    this.dataConcepto.precio_unitario = 0;
    this.dataConcepto.precio_total = 0;
    this.dataConcepto.cant_art = 1;
    this.dataConcepto.um = this.unidadMedidaOptions[0];
    if(!this.cuenta.fecha_vencimiento){
      this.cuenta.fecha_vencimiento = this.today;
    }
    
    if(!this.cuenta.fecha_venta){
      this.cuenta.fecha_venta = this.today;
    }
    
    if(!this.cuenta.total){
      this.cuenta.total = 0;
    }
    
    if(!this.cuenta.dias_credito){
      this.cuenta.dias_credito = 0;
    }

    //console.log('cuenta', this.cuenta);
    this.catalogos2 = this.catalogos;

    const headers = new HttpHeaders({
      "X-API-KEY": 'RpoXa1m5s59vDMOIDmI5uJCdWP2pgnaAuuyTREv'
    });

    this.http.post<any>('https://api.ordex.com.mx/catalogos/convenios_ordex', this.user, { headers: headers })
      .subscribe(data => {
          
          this.convenios = data.convenios;
          console.log(this.convenios);

      }, error => {
        console.error('Error en la solicitud', error);
        this.dismissLoading();
      });

  }


  cerrarModal() {
    if (this.modal) {
      this.modal.dismiss();
      this.modal = null;
    }
  }

  trackById(index, item) {
    return item.id_catalogo;
  }

  actualizarValor(event: any) {
    const valorNumerico = parseFloat(event.target.value.replace(/[^0-9.-]+/g, "")); // Remueve cualquier cosa que no sea número o punto
    this.cargoTotal = valorNumerico; // Actualiza el valor numérico
    this.cargoTotalFormateado = this.currencyPipe.transform(valorNumerico, 'USD', true, '1.2-2');
  }

  async enviarCuenta() {

    const user = JSON.parse(localStorage.getItem('user'));

    if  (!this.cuenta.id) {
      this.cuenta.id = '';
      this.cuenta.id_empresa = user.id_empresa;
      this.cuenta.id_sucursal = user.id_sucursal;
      this.cuenta.id_usuario = user.id_usuario;
      this.cuenta.id_cliente = this.cliente.id_cliente;
      this.cuenta.id_status = 2;
      this.cuenta.num_corte = 1;
    }

    if(this.cuenta.total == 0 || this.cuenta.total == ''){
      this.mostrarAlerta('Atención!', 'No se puede dejar el campo "Precio Total" en 0 o vacío.');
      return;
    }

    if(this.cuenta.dias_credito == 0 || this.cuenta.dias_credito == ''){
      this.mostrarAlerta('Atención!', 'No se puede dejar el campo "Días" en 0 o vacío.');
      return;
    }

    

    await this.presentLoading('Cargando...');
    
    const headers = new HttpHeaders({
      "X-API-KEY": 'RpoXa1m5s59vDMOIDmI5uJCdWP2pgnaAuuyTREv'
    });

    this.venta_detalle.forEach((item) => {
      delete item.concepto;
    })

    const ventaSend = {
      ventas:this.cuenta,
      venta_detalles:this.venta_detalle,
    }

    this.http.post<any>('https://api.ordex.com.mx/ventas/crud_ventas_app', ventaSend, { headers: headers })
      .subscribe(data => {
          
          ////console.log(this.catalogos);
          this.dismissLoading();
          this.mostrarAlerta('Bien!', 'Cuenta Agregada con exito');
          this.cuenta = {};
          this.modalController.dismiss().then(() => {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/detalles-cliente', this.cliente.id_cliente]);  
            });
          });


      }, error => {
        console.error('Error en la solicitud', error);
        this.dismissLoading();
      });

    

  }

  calcularFechaVencimiento() {
    if (this.cuenta.fecha_venta && this.cuenta.dias_credito) {
      let fechaRegistro = new Date(this.cuenta.fecha_venta);
      fechaRegistro.setDate(fechaRegistro.getDate() + Number(this.cuenta.dias_credito));

      // Actualiza la fecha de vencimiento en el formato 'YYYY-MM-DD'
      this.cuenta.fecha_vencimiento = fechaRegistro.toISOString().split('T')[0];
    }
  }


  async presentLoading(message: string) {
    const loading = await this.loadingController.create({
      message: message,
    });
    await loading.present();
  }

  async dismissLoading() {
    await this.loadingController.dismiss();
  }

  addConcepto() {

    if(this.dataConcepto.id_catalogo == 0 || this.dataConcepto.id_catalogo == ''|| !this.dataConcepto.id_catalogo){
      this.mostrarAlerta('Atención!', 'No se puede dejar el campo "Concepto" vacío.');
      return;
    }
    if(this.dataConcepto.precio_unitario == 0 || this.dataConcepto.precio_unitario == ''|| !this.dataConcepto.precio_unitario){
      this.mostrarAlerta('Atención!', 'No se puede dejar el campo "Precio Unitario" del concepto vacío.');
      return;
    }

    const objCatalogo = this.catalogos.find(item => item.id_catalogo == this.dataConcepto.id_catalogo);
    
    this.dataConcepto.precio_total = (this.dataConcepto.cant_art * this.dataConcepto.precio_unitario);
    this.dataConcepto.id_empresa = this.user.id_empresa;
    this.dataConcepto.id_sucursal = this.user.id_sucursal;
    this.dataConcepto.id_usuario = this.user.id_usuario;
    this.dataConcepto.concepto = objCatalogo.concepto;

    this.venta_detalle.push(this.dataConcepto);

    console.log(this.venta_detalle);
    this.dataConcepto = {}
    this.dataConcepto.precio_unitario = 0;
    this.dataConcepto.cant_art = 1;
    this.calcularTotal();

  }

  deleteConcepto(index: number): void {
    this.venta_detalle.splice(index, 1);
    this.calcularTotal();
  }

  calcularTotal() {
    this.cuenta.total = 0;
    this.venta_detalle.forEach((item) => {
      this.cuenta.total += Number(item.precio_total);
    })
  }
  

}
